import { get, isEmpty, size } from "lodash";
import { compose, withHandlers } from "recompose";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";
import { Banner, withSnackbar } from "@dpdgroupuk/mydpd-ui";

import { M } from "../constants";
import ERROR_CODES from "../constants/errorCodes";
import { SHOW_ALERT_DISPLAY_TIME } from "../constants/snackbar";
import { getErrorMessage } from "../util/error";

const errorMapper = ({ error, snackbar, banner, entityName }) => {
  const errorCode = get(error, "statusCode");
  switch (errorCode) {
    case ERROR_CODES.FIELD_VALIDATION: {
      const mappedErrors = get(error, "errors");
      if (!isEmpty(mappedErrors)) {
        return banner.showByType(BANNERS_TYPES.ALERT, {
          message: M.PAGE_ERROR(size(mappedErrors)),
          closable: true,
          showIcon: true,
          actions: [],
        });
      } else {
        throw error;
      }
    }
    default: {
      return snackbar.showAlert({
        message: getErrorMessage(error, entityName),
        displayTime: SHOW_ALERT_DISPLAY_TIME,
      });
    }
  }
};

export default compose(
  withSnackbar,
  Banner.withBanner,
  withHandlers({
    withFormSubmitNotification:
      ({ snackbar, banner }) =>
      func =>
      async (...args) => {
        banner.hideByType(BANNERS_TYPES.ALERT);
        try {
          const res = await func(...args);
          snackbar.showSuccess({
            message: M.CHANGES_SAVED,
            displayTime: SHOW_ALERT_DISPLAY_TIME / 3,
          });
          return res;
        } catch (error) {
          errorMapper({
            error,
            snackbar,
            banner,
          });
        }
      },
    withErrorNotification:
      ({ snackbar }) =>
      (func, { entityName = "" } = {}) =>
      async (...args) => {
        try {
          return await func(...args);
        } catch (error) {
          errorMapper({
            error,
            snackbar,
            entityName,
          });
        }
      },
  })
);
