import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../../../../apis";

export const invoiceDefaultsActionTypes = createActionTypes(
  "INVOICE_DEFAULTS",
  {
    UPDATE_INVOICE_SETTINGS: createAsyncActionTypes("UPDATE_INVOICE_SETTINGS"),
  }
);

export const updateForm = createAsyncAction(
  body => userApis.updateInvoiceDefaults(body),
  invoiceDefaultsActionTypes.UPDATE_INVOICE_SETTINGS
);
